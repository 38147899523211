import { useNavigate } from 'react-router-dom';
import React, { useMemo } from 'react';
import { useGlobalFilter, usePagination, useSortBy, useTable} from 'react-table';
import {Flex, Table, Tbody, Td, Text, Tr, Link} from '@chakra-ui/react';
import { toTitleCase } from '../../utils/common';
import moment from 'moment';

function JobTableList(props) {
  const { columnsData, tableData, totalJobsCount, loading } = props;
  const navigate = useNavigate();
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const tableInstance = useTable(
    {columns, data, manualPagination: true},
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    page,
    prepareRow,
  } = tableInstance;

  const NoOpenJobs = () => {
    if (!loading && totalJobsCount == 0){
      return <>
        <Flex fontSize={'18px'}>
          <Text>There are no jobs open at the moment. Please visit again to get newly open jobs</Text>
        </Flex>
      </>
    }
  }

  return (
    <>
      <Flex direction="column">
        <NoOpenJobs />
        <Table {...getTableProps()}>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              const job = tableData[index]
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, subIndex) => {
                    let data = '';
                    if (cell.column.Header === 'Job Details') {
                      data = (
                        <Flex direction={'column'} justifyContent={'center'} padding={'50px 0px'} fontSize={'16px'} color={'#00756a'}>
                          <Link
                            _hover={{
                              cursor: 'pointer'
                            }}
                            href={`/job?id=${job?.job_public_url}`}
                            color={'#00756a'}
                            fontSize={'24px'}
                            fontWeight={'bold'}
                          >
                            <Text _hover={{textDecoration: 'underline'}}>{cell.value}</Text>
                          
                          
                          <Flex alignItems={'center'} fontSize={'16px'} fontWeight={'normal'} mt={6}>
                            <Text fontSize={'lg'}>at {job?.business?.business_name}</Text>
                            <Text ml={5} as={'b'}>
                              {job?.job_location_extended && toTitleCase(job?.job_location_extended.replaceAll('_', ' '))}                                  
                            </Text>                                  
                            <Text ml={5}>{job?.job_location}</Text>
                            <Text ml={5}>{job?.job_function}</Text>
                            <Text ml={5}>{job?.job_employment_type}</Text>
                          </Flex>

                          <Text mt={6} fontSize={'16px'} fontWeight={'normal'}>
                            Posted {moment.utc(job?.datetime_created).fromNow()}
                          </Text>
                          </Link>
                        </Flex>
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        mb={5} borderBottom={'1px solid'} borderBottomColor={'gray.200'}
                        p={0}
                      >
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Flex>
    </>
  );
}

export default JobTableList;
