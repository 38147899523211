import React, { useEffect, useState } from 'react';
import JobTableList from './JobTableList';
import { Flex, Heading, Spinner, Text, useToast } from '@chakra-ui/react';
import { jobTableColumnList } from './JobTableColumnList';
import Pagination from './../common/pagination';
import { getRecentPublicJobs } from '../../services/jobService';
import JobApplyLayout from '../../layouts/auth/types/JobApplyLayout';

function JobList() {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0)
  const recordsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchRecentPublicJobs();
  }, [currentPage]);

  useEffect(() => console.log(tableData))

  const fetchRecentPublicJobs = () => {
    setLoading(true);
    let objData = {
      records_per_page: recordsPerPage,
      page_id: currentPage,
    };
    getRecentPublicJobs(objData)
      .then(res => {
        setLoading(false);
        if (res?.data?.records?.length > 0 && res?.result) {
          setTotalRecords(res?.data.total_records)
          setTableData(res?.data?.records);        
        } else {
          setTableData([]);
        }
      })
      .catch(err => {
        setLoading(false);
        toast({
          title: err?.toString(),
          status: 'error',
          duration: 3000,
        });
      });
  };

  const reRenderTableData = () => {
    fetchRecentPublicJobs();
  };

  const JobsLoader = () => {
    if (loading){
      return <>
        <Flex alignItems="center" justifyContent="center" mt="10" h="50vh">
          <Spinner size="lg" />
        </Flex>
      </>
    }
  }

  return (
    <JobApplyLayout
        cardTop={{ base: '10px', md: '14vh' }}
        cardBottom={'100px'}
        mx="0px"
    >
      <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
      
        <Flex mb={'50px'}>
          <Heading fontSize="30px" color={'#313b46'}>
            Recent Jobs
          </Heading>          
        </Flex>

        <JobsLoader />

        <JobTableList
          tableData={tableData}
          columnsData={jobTableColumnList}
          onDataReceived={reRenderTableData}
          totalJobsCount={totalRecords}
          loading={loading}
        />

        <Pagination
        totalRows={totalRecords}
        pageChangeHandler={setCurrentPage}
        page={currentPage}
        rowsPerPage={recordsPerPage}
      />
      </Flex>
    </JobApplyLayout>
    );
  }

export default JobList;
